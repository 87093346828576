<template>
  <v-card class="tp-border-thin rounded px-3 py-2" flat>
    <v-row class="align-center">
      <v-col cols="5">
        <div class="d_flex align-center px-0" v-if="!item.option_id">
          <v-autocomplete
            class="text-body-1 rounded-lg mr-3"
            clearable
            dense
            flat
            hide-details
            :items="searchProductOptions"
            item-text="SKU"
            item-value="id"
            :menu-props="{ maxWidth: '332px' }"
            no-data-text="Không có dữ liệu"
            no-filter
            placeholder="Tìm SKU mới và ấn enter"
            prepend-inner-icon="mdi-magnify"
            return-object
            solo
            outlined
            :search-input.sync="productOptionSearchKey"
            @change="getProductOptions($event)"
          >
            <template v-slot:item="data">
              <v-list-item-content :title="data.item.name">
                <v-list-item-title
                  class="font-weight-medium"
                  v-html="data.item.product_name"
                ></v-list-item-title>
                <div class="grey--text text--darken-2 text-subtitle-2">
                  <span class="font-weight-bold">{{ data.item.SKU }}</span>
                  <span v-if="data.item.name"> - </span>
                  <span class="tag-p__mb-0" v-html="data.item.name"></span>
                </div>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-btn
            class="cyan lighten-5 rounded-lg px-0"
            height="40px"
            width="40px"
            depressed
            color="primary"
            icon
            @click="openModalRemoveOption"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="d_flex align-center px-0" v-else>
          <v-text-field
            class="text-body-1"
            clear-icon="mdi-close-circle"
            clearable
            dense
            flat
            hide-details
            outlined
            readonly
            solo
            :value="item.option_sku + ' - ' + item.product_name"
            @click:clear="clearOption"
          ></v-text-field>
        </div>
      </v-col>
      <v-col class="d_flex flex-column justify-center align-center" cols="3">
        <tp-input-price
          v-model="item.created_price"
          custom-class="text-body-1"
          custom-style="width: 136px"
          dense
          flat
          hide-details
          outlined
          solo
          plaholder="Giá nhập"
        ></tp-input-price>
      </v-col>
      <v-col class="d_flex flex-column justify-center align-center" cols="3">
        <div class="">
          <v-chip class="cyan lighten-4 font-weight-medium rounded-lg" label>
            {{ item.serial_number }}
          </v-chip>
        </div>
      </v-col>
      <v-col class="d_flex justify-end" cols="1">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click="remove"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
    <modal-product-option></modal-product-option>
  </v-card>
</template>

<script>
import ModalProductOption from "@/core/components/modals/ModalProduct";

export default {
  components: {
    ModalProductOption
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    remove: {
      type: Function
    }
  },
  data() {
    return {
      productOptionSearchKey: null
    };
  },
  computed: {
    searchProductOptions() {
      return this.$store.getters["SERIAL/searchProductOptions"];
    }
  },
  watch: {
    productOptionSearchKey(val) {
      this.$store.dispatch("SERIAL/getSearchProductOptions", { search: val });
    }
  },
  methods: {
    async getProductOptions(val) {
      await this.$store.dispatch("REMOVE_ACCESS/setRemoveAnAccessGearsOption", {
        index: this.index,
        option: val
      });
      this.productOptionSearchKey = null;
    },
    openModalRemoveOption() {
      this.$modal.show({
        name: "modal-product"
      });
    },
    clearOption() {
      this.$store.dispatch("REMOVE_ACCESS/clearOption", this.index);
      this.$forceUpdate();
    },
    clearPrice() {
      this.$store.dispatch("REMOVE_ACCESS/clearPrice", this.index);
      this.$forceUpdate();
    },
    async setGearPrice(e) {
      await this.$store.dispatch("REMOVE_ACCESS/setRemoveAnAccessGearsPrice", {
        index: this.index,
        price: e.target.value
      });
      this.$forceUpdate();
    }
  }
};
</script>
