<template>
  <v-card class="card-wrap" flat>
    <v-stepper class="elevation-0" v-model="currentStep" non-linear>
      <v-stepper-header class="elevation-0">
        <v-stepper-step
          class="cursor-pointer"
          :complete="currentStep > 1"
          step="1"
          @click="currentStep = 1"
        ></v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          class="cursor-pointer"
          :complete="currentStep > 2"
          step="2"
          @click="currentStep = 2"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          class="cursor-pointer"
          step="3"
          @click="currentStep = 3"
        >
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-8" flat>
            <v-card-title class="justify-center">
              Chọn sản phẩm tháo linh kiện
            </v-card-title>
            <v-text-field
              class="rounded-lg text-body-1"
              clearable
              dense
              hide-details
              outlined
              placeholder="Nhập serial"
              prepend-inner-icon="mdi-magnify"
              @keypress.enter.prevent="completeStep1"
            ></v-text-field>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-8" flat>
            <v-card-title class="justify-center">
              Tháo linh kiện
            </v-card-title>
            <v-text-field
              class="rounded-lg text-body-1"
              clearable
              dense
              hide-details
              outlined
              placeholder="Nhập serial"
              prepend-inner-icon="mdi-magnify"
              @keypress.enter.prevent="completeStep2"
            ></v-text-field>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-8" flat>
            <v-card-title class="justify-center">
              Thiết lập cấu hình mới
            </v-card-title>
            <div class="text-center mb-4">
              Chọn SKU có cấu hình giống với máy đã thêm linh kiện hoặc tạo mới
              SKU.
            </div>
            <div class="d_flex align-center px-0">
              <v-autocomplete
                class="text-body-1 rounded-lg mr-3"
                clearable
                dense
                flat
                hide-details
                :items="searchProductOptions"
                item-text="SKU"
                item-value="id"
                :menu-props="{ maxWidth: '332px' }"
                no-data-text="Không có dữ liệu"
                no-filter
                placeholder="Tìm SKU mới và ấn nhấn enter"
                prepend-inner-icon="mdi-magnify"
                return-object
                solo
                outlined
                :search-input.sync="productOptionSearchKey"
                @change="getProductOptions($event)"
              >
                <template v-slot:item="data">
                  <v-list-item-content :title="data.item.name">
                    <v-list-item-title
                      class="font-weight-medium"
                      v-html="data.item.product_name"
                    ></v-list-item-title>
                    <div class="grey--text text--darken-2 text-subtitle-2">
                      <span class="font-weight-bold">{{ data.item.SKU }} </span>
                      <span v-if="data.item.name"> - </span>
                      <span class="tag-p__mb-0" v-html="data.item.name"></span>
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-tooltip
                bottom
                color="white"
                content-class="elevation-4 px-2"
                nudge-top="5px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="cyan lighten-5 rounded-lg px-0"
                    height="40px"
                    width="40px"
                    depressed
                    color="primary"
                    icon
                    @click="openModalProduct"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <div class="black--text text-body-2" style="width: 180px">
                  Sử dụng với trường hợp máy được tạo ra để đăng bán trên
                  website, cần thêm đầy đủ thông tin chi tiết.
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex align-center justify-center mt-3">
              <v-tooltip
                bottom
                color="white"
                content-class="elevation-4 px-2"
                nudge-top="5px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="green lighten-5 green--text rounded-lg mr-3"
                    depressed
                    text
                    @click="openModalProductOptionQuickCreate"
                  >
                    Tạo nhanh SKU
                  </v-btn>
                </template>
                <div class="black--text text-body-2" style="width: 180px">
                  Sử dụng với trường hợp mã sản phẩm này dùng một lần và không
                  hoặc ít tái sử dụng sau này.
                </div>
              </v-tooltip>
              <v-tooltip
                bottom
                color="white"
                content-class="elevation-4 px-2"
                nudge-top="5px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="cyan lighten-5 primary--text rounded-lg"
                    depressed
                    text
                    @click="useOldProductOption"
                  >
                    Dùng SKU cũ
                  </v-btn>
                </template>
                <div class="black--text text-body-2" style="width: 180px">
                  Sử dụng với trường hợp máy sau thay đổi giống 100% tình trạng
                  và giá bán với máy gốc. Hoặc đây chỉ là khâu trung gian, sẽ
                  tiếp tục nâng cấp ngay sau đây.
                </div>
              </v-tooltip>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <modal-product-option></modal-product-option>
  </v-card>
</template>

<script>
import ModalProductOption from "@/core/components/modals/ModalProduct";

export default {
  components: {
    ModalProductOption
  },
  data() {
    return {
      currentStep: 1,
      productOptionSearchKey: null
    };
  },
  computed: {
    oldProductOption() {
      return this.$store.getters["REMOVE_ACCESS/oldOption"];
    },
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },
    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },
    searchProductOptions() {
      return this.$store.getters["SERIAL/searchProductOptions"];
    }
  },
  watch: {
    productOptionSearchKey(val) {
      this.$store.dispatch("SERIAL/getSearchProductOptions", { search: val });
    }
  },
  methods: {
    completeStep1(e) {
      this.$store.dispatch(
        "REMOVE_ACCESS/setRemoveAnAccessOld",
        e.target.value
      );
    },

    completeStep2(e) {
      this.$store.dispatch(
        "REMOVE_ACCESS/setRemoveAnAccessGears",
        e.target.value
      );
    },

    generateSku(sku) {
      const arr = sku.split("-");

      if (arr.length === 1) {
        return `${sku}-T`;
      } else {
        return `${sku}T`;
      }
    },

    getProductOptions(val) {
      this.$store.dispatch("REMOVE_ACCESS/setRemoveAnAccessNew", val);
    },

    async openModalProduct() {
      if (this.oldProductOption) {
        await this.$store.dispatch(
          "PRODUCT/getProductById",
          this.oldProductOption.product_id
        );

        if (this.productStatusRequest.value === "success-getProductById") {
          await this.$store.dispatch(
            "PRODUCT_OPTION/getProductOptionsByProductId",
            this.oldProductOption.product_id
          );

          this.$modal.show({
            name: "modal-product"
          });
        }
      } else {
        this.$modal.show({
          name: "modal-product"
        });
      }
    },

    async openModalProductOptionQuickCreate() {
      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionById", {
        productOptionId: this.oldProductOption.option_id
      });

      this.productOption.SKU = await this.generateSku(
        this.oldProductOption.option_sku
      );

      this.$modal.show({
        name: "modal-product-option-quick-create",
        payload: {
          oldProductOption: this.oldProductOption,
          onCompleteCreating: option => {
            this.$store.dispatch("REMOVE_ACCESS/setRemoveAnAccessNew", option);
          }
        }
      });
    },

    useOldProductOption() {
      this.getProductOptions({
        id: this.oldProductOption.option_id,
        SKU: this.oldProductOption.option_sku,
        name: this.oldProductOption.option_name,
        product_name: this.oldProductOption.product_name,
        price: this.oldProductOption.price
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.card-wrap {
  height: 100%;
}
</style>
