<template>
  <div class="px-5">
    <div class="d_flex align-center mb-5">
      <div class="font-weight-bold text-h5">Tháo linh kiện</div>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          class="rounded-lg mr-4"
          color="white"
          depressed
          @click="$router.go(-1)"
        >
          Hủy
        </v-btn>
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="removeAnAccess"
        >
          Xác nhận tháo
        </v-btn>
      </div>
    </div>

    <v-container class="py-0" fluid>
      <v-row>
        <v-col class="scrollable pl-0 py-0" cols="4">
          <card-stepper></card-stepper>
        </v-col>
        <v-col class="scrollable pr-0 py-0" cols="8">
          <card-preview></card-preview>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardPreview from "./components/CardPreview";
import CardStepper from "./components/CardStepper";

export default {
  components: {
    CardPreview,
    CardStepper
  },
  methods: {
    async removeAnAccess() {
      await this.$store.dispatch("REMOVE_ACCESS/removeAnAccess");
      if (this.removeAccessRequestStatus.value === "success-removeAnAccess") {
        this.$router.push({ name: "goods_removing-accessories" });
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã tháo linh kiện"
          }
        });
      }
    }
  },
  computed: {
    removeAccessRequestStatus() {
      return this.$store.getters["REMOVE_ACCESS/statusRequest"];
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 56px - 24px);
  overflow: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 56px - 24px);
  }
}
</style>
